import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewRoutingModule } from './views.routing';
import { SharedModule } from '../shared/shared.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { ComponentsCarouselModule } from 'src/app/components/carousel/components.carousel.module';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { HomeComponent } from './home/home.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AuthGuard } from '../shared/auth.guard';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

//import { MainRtModule } from './app/main-rt/main-rt.module'

import { ReportViewerModule } from './app/angular-tms/reports-tms/all-reports/report-viewer/report-viewer.module';
//import { ReportGridViewerModule } from './app/angular-tms/reports-tms/all-reports/report-grid-viewer/report-grid-viewer.module';

//import { ReportViewerModule } from './app/report-viewer/report-viewer.module';
import { ReportGridViewerModule } from './app/report-grid-viewer/report-grid-viewer.module';
@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    ViewRoutingModule,
    SharedModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    ComponentsCarouselModule,
    TabsModule.forRoot(),
    BrowserAnimationsModule,
    HeadroomModule,
    ScrollToModule.forRoot(),
    TooltipModule.forRoot(),
    //MainRtModule
    //    ,
    ReportViewerModule,
    ReportGridViewerModule
  ],
  providers: [AuthGuard],
})
export class ViewsModule {}
