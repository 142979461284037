import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { ViewsComponent } from './views/views.component';

import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { ServiceLocator } from './views/app/@core/Services/locator.service';
import { FormBuilder } from '@angular/forms';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderService, PB_DIRECTION, POSITION, SPINNER } from "ngx-ui-loader";
//import { ComponentModule } from './views/app/@core/components/components.module';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "",
  "bgsOpacity": 0.5,
  "bgsPosition": "center-center",
  "bgsSize": 150,
  "bgsType": "three-strings",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "",
  "fgsPosition": "center-center",
  "fgsSize": 150,
  "fgsType": "three-strings",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(190,106,106,0)",
  "pbColor": "",
  "pbDirection": "ltr",
  "pbThickness": 15,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), 
    // NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
  //  ComponentModule
  ],
  declarations: [
    AppComponent ,ViewsComponent
  ],
  providers: [FormBuilder,NgxUiLoaderService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
