import { AfterViewInit, Injectable, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from 'rxjs';
import { BaseService } from '../Services/base.service';
import { ServiceLocator } from '../Services/locator.service';
import { UserRole } from '../../../../shared/auth.roles';
import { NavigationExtras, Router } from '@angular/router';
import { Broadcast } from '../Services/broadcast.service';
import { ToolbarEvent } from '../Services/toolbar.service';
import { MainComponent } from './main.component';
import { LookupResult } from '../components/lookup/lookup.component';




@Injectable({
    providedIn: "root",
})
export abstract class BaseComponent extends MainComponent implements OnInit, AfterViewInit {

    public formBuilder: any;
    public myForm: FormGroup;
    public loader: any;
    public masterState: string = '';
    public masterDisabled = false;
    public listingState: string = '';
    public listingDisabled = false;
    // public isLoader: boolean = true;
    newTabRouter: any;
    public baseService: any;

    public toolbarEvent!: ToolbarEvent;
    private toolbarSubscription!: Subscription;
    private lookupSubscription!: Subscription;

    constructor() {
        super();

        this.formBuilder = ServiceLocator.injector.get(FormBuilder);
        this.baseService = ServiceLocator.injector.get(BaseService);
        this.loader = ServiceLocator.injector.get(NgxUiLoaderService);
        this.newTabRouter = ServiceLocator.injector.get(Router);
    }

    public title: string = "";
    public path: string = "";
    public isToolbar: boolean = true;
    public hideNew: boolean = true;
    public hideSave: boolean = true;
    public hideDelete: boolean = true;
    public hideSearch: boolean = true;
    public hidePrint: boolean = true;
    public disabledNew: boolean = false;
    public disabledSave: boolean = false;
    public disabledDelete: boolean = false;
    public disabledSearch: boolean = false;
    public disabledPrint: boolean = false;
    public isCustomSearch: boolean = false;

    public disabledinput: boolean = false;
    

    public SearchController: string = "";
    public SearchRoute: string = "";
    public SearchParamters: any[] = [];
    public SearchModule: string = "";


    async ngOnInit() {

    }

    async ngAfterViewInit() {
        this.toolbarEvent = ServiceLocator.injector.get(ToolbarEvent);
        this.toolbarSubscription = this.toolbarEvent
            .click()
            .subscribe(async (arg) => {
                switch (arg.key) {
                    case "new":
                        await this.addNew();
                        break;
                    case "save":
                        await this.saving();
                        break;
                    case "delete":
                        await this.deleting();
                        break;
                    case "search":
                        await this.search();
                        break;
                    case "print":
                        await this.print();
                        break;
                }
            });
        if (this.title !== "")
            this.broadcast.publish<string>("title", this.title);
        if (this.path !== "")
            this.broadcast.publish<string>("path", this.path);
        
        this.broadcast.publish<Boolean>("isToolbar", this.isToolbar);
        this.broadcast.publish<Boolean>("hideNew", this.hideNew);
        this.broadcast.publish<Boolean>("hideSave", this.hideSave);
        this.broadcast.publish<Boolean>("hideDelete", this.hideDelete);
        this.broadcast.publish<Boolean>("hideSearch", this.hideSearch);
        this.broadcast.publish<Boolean>("hidePrint", this.hidePrint);
        this.broadcast.publish<Boolean>("disabledNew", this.disabledNew);
        this.broadcast.publish<Boolean>("disabledSave", this.disabledSave);
        this.broadcast.publish<Boolean>("disabledDelete", this.disabledDelete);
        this.broadcast.publish<Boolean>("disabledSearch", this.disabledSearch);
        this.broadcast.publish<Boolean>("disabledPrint", this.disabledPrint);
        this.broadcast.publish<string>("SearchController", this.SearchController);
        this.broadcast.publish<string>("SearchRoute", this.SearchRoute);
        this.broadcast.publish<string>("SearchModule", this.SearchModule);
        this.broadcast.publish<any>("SearchParamters", this.SearchParamters);

        this.lookupSubscription = this.broadcast
            .observable<LookupResult>("lookup")
            .subscribe(async (arg) => {
                
                if (arg.key === "lookup" && arg.value && arg.value.data && arg.value.data.row) {
                    
                    let data = arg.value.data.row;
                    let first = Object.keys(arg.value.data.row)[0];
                    let val = 'mfiled=' + first + '&fvalue=' + data[first] + '&CompanyID=' + localStorage.getItem('CompanyID') + '&LocationID=' + localStorage.getItem('LocationID') + '&UserID=' + localStorage.getItem('UserID')
                    
                    const dto = await this.getObject(arg.value.controller, 'getSearchby', val, arg.value.moduleName);
                    
                    if (dto && dto.length && dto.length > 0) {
                        data = dto[0];
                        if (this.myForm && this.myForm.controls)
                            Object.keys(this.myForm.controls).map(u => {
                                if (data[u])
                                    this.myForm.controls[u].setValue(data[u])
                            })
                        await this.AfterEdit(data);
                    }
                    else if (data) {
                        if (this.myForm && this.myForm.controls)
                            Object.keys(this.myForm.controls).map(u => {
                                if (data[u])
                                    this.myForm.controls[u].setValue(data[u])
                            })
                        await this.AfterEdit(data);
                    }
                }
            });

    }

    protected async addNew() { }
    protected async saving() { }
    protected async deleting() { }
    protected async print() { }

    async save(controller, method, data, formControl?: FormGroup, list?: any, listKey?: any, moduleName?: any, isloader: boolean = true, isCheck: boolean = true) {
        if (formControl) {
            this.myForm = formControl;
        }
        let rtn: string = "";
        let ctrl;
        let isDirty: boolean = false;
        let dirtycontrol: Array<AbstractControl> = [];

        if (this.myForm) {
            this.myForm.reset(this.myForm.value);
            Object.keys(this.myForm.controls).map((controlName) => {

                ctrl = this.myForm.get(controlName);

                if (ctrl) {

                    ctrl.markAsDirty({ onlySelf: true });
                    ctrl.markAsTouched({ onlySelf: true });
                }
                if (!isDirty) {

                    isDirty = ctrl?.errors !== null;
                } else {

                    if (ctrl) dirtycontrol.push(ctrl);
                }
            });
        }
        if (dirtycontrol && dirtycontrol.length && dirtycontrol.length > 0) {
            let invalidControl: any = document.getElementsByClassName(
                "ng-invalid"
            );
            if (invalidControl && invalidControl[1]) {
                invalidControl[1].focus();
            }
        }
        if (isCheck) {
            if (!isDirty && dirtycontrol.length === 0) {
                // if (this.loader) this.loader.start();
                if (list) {
                    data[listKey] = list;
                }

                return await this.baseService.post(controller, method, data, moduleName, isloader).then(x => {
                    return x;
                });
            } else {
                rtn = "Please fill required fields.";
            }
        } else {
            if (list) {
                data[listKey] = list;
            }

            return await this.baseService.post(controller, method, data, moduleName, isloader).then(x => {
                return x;
            });
        }
        return rtn;
    }

    async get(controller, method, params?: any, moduleName?: any, isloader: boolean = true) {
        return await this.baseService.get(controller, method, params, moduleName, isloader).then(x => {
            return x;
        });
    }

    async savingDetail(controller, method, data: any, moduleName?: any, isloader: boolean = true) {

        if (data) {
            return await this.baseService.post(controller, method, data, moduleName, isloader).then(x => {
                return x;
            });
        }
    }

    async delete(controller, method, key, moduleName?: any, isloader: boolean = true) {
        return await this.baseService.delete(controller, method, key, moduleName, isloader).then(x => {
            return x;
        });
    }

    async clear(formControl?: FormGroup) {
        debugger        
        if (formControl) {
            this.myForm = formControl;
        }
        if (this.myForm && this.myForm.controls) {
            Object.keys(this.myForm.controls).map((controlName) => {
                let ctr = this.myForm.get(controlName);
                if (ctr) {
                    this.myForm.controls[controlName].setValue(undefined);
                    ctr.markAsUntouched({ onlySelf: true });
                    ctr.markAsPristine({ onlySelf: true });
                }
            });
        }
    }

    async Npdisabled(formControl?: FormGroup) {
        if (formControl) {
            this.myForm = formControl;
        }
        if (this.myForm && this.myForm.controls) {
            Object.keys(this.myForm.controls).map((controlName) => {
                let ctr = this.myForm.get(controlName);
                if (ctr) {
                    this.myForm.controls[controlName].setValue('0');
                    
                    //this.myForm.controls[controlName].disable();
                    //ctr.markAsUntouched({ onlySelf: true });
                    //ctr.markAsPristine({ onlySelf: true });
                }
            });
        }
    }
    async Npenabled(formControl?: FormGroup) {
        if (formControl) {
            this.myForm = formControl;
        }
        if (this.myForm && this.myForm.controls) {
            Object.keys(this.myForm.controls).map((controlName) => {
                let ctr = this.myForm.get(controlName);
                if (ctr) {
                    //this.myForm.controls[controlName].setValue('0');
                    this.myForm.controls[controlName].enabled;

                    ctr.markAsUntouched({ onlySelf: true });
                    ctr.markAsPristine({ onlySelf: true });
                }
            });
        }
    }
    public getMenusParent() {
        let menu: any = localStorage.getItem('menus');
        menu = JSON.parse(menu);
        if (menu && menu.menus) {
            var result = menu.menus.filter(o => Number(o.parentID) === 0);
            if (result && result.length > 0) {

                for (let index = 0; index < result.length; index++) {
                    const main = result[index];
                    result[index].subs = [];
                    // result[index].roles = [UserRole.Admin, UserRole.Editor];

                    var sub = menu.menus.filter(o => Number(o.parentID) === Number(main.id))

                    if (sub && sub.length > 0) {

                        result[index].subs = sub;
                        for (let ind = 0; ind < sub.length; ind++) {
                            // sub[index].roles = [];
                            const element = sub[ind];
                            // sub[index].roles = [UserRole.Admin, UserRole.Editor];
                            sub[ind].subs = [];

                            let subchild = menu.menus.filter(m => Number(m.parentID) === Number(element.id));

                            sub[ind].subs = subchild
                        }
                    }
                }
            }

            return result;
        }
    }

    async getMenusByID(id) {
        let menu: any = localStorage.getItem('menus');

        menu = JSON.parse(menu);

        if (menu && menu.menus) {
            let module: any = menu.menus.filter(o => Number(o.parentID) === Number(id));

            if (id > 0 && module) {

                for (let index = 0; index < module.length; index++) {

                    const element = module[index];
                    module[index].subs = [];
                    module[index].roles = [UserRole.Admin, UserRole.Editor];
                    let subchild = menu.menus.filter(m => Number(m.parentID) === element.id);

                    module[index].subs = subchild
                }
                return module;
            }
        }
    }

    async postReport(controller, method, data, moduleName?: any, isloader: boolean = true) {

        return await this.baseService.postReport(controller, method, data, moduleName, isloader).then(x => {

            return x;
        });
    }
    async postRespone(controller, method, data, moduleName?: any, isloader: boolean = true) {

        return await this.baseService.postRespone(controller, method, data, moduleName, isloader).then(x => {

            return x;
        });
    }
    async openReportViewer(data) {
        debugger
        let obj = JSON.stringify(data);
        const url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['./ReportViewer'], { queryParams: { obj } }));
        debugger
        window.open(url, '_blank');        
    }
    async openReportGridViewer(data) {

        let obj = JSON.stringify(data);
        const url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['./reportGridViewer'], { queryParams: { obj } }));
        window.open(url, '_blank');
    }

    async postMethod(controller, method, data, moduleName?: any, isloader: boolean = true) {
        return await this.baseService.post(controller, method, data, moduleName, isloader).then(x => {
            return x;
        });
    }

    public async SearchOveride() { }

    protected async AfterEdit(data) { }
    public async search() {
        debugger
      
        if (!this.isCustomSearch) {
            
            this.broadcast.observable<string>("SearchController").subscribe((x) => {
                
                this.SearchController = x.value;
            });
            this.broadcast.observable<string>("SearchRoute").subscribe((x) => {
                
                this.SearchRoute = x.value;
            });
            this.broadcast.observable<string>("SearchModule").subscribe((x) => {
                
                this.SearchModule = x.value;
            });
            this.broadcast.observable<any>("SearchParamters").subscribe((x: any) => {
                
                this.SearchParamters = x.value;
            });
            
            if (this.SearchController && this.SearchRoute)
                await this.showLookup(this.SearchController, this.SearchRoute, this.SearchParamters, this.SearchModule);
        }
        else {
            
            await this.SearchOveride();
        }
    }

    async getObject(controller, method, params?: any, moduleName?: any, isloader: boolean = true) {
        const result = await this.baseService.get(controller, method, params, moduleName, isloader);
        return result;
    }
}