import { Injectable, OnInit, ViewChild, AfterViewInit, ViewContainerRef, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Broadcast } from '../Services/broadcast.service';
import { LookupComponent } from '../components/lookup/lookup.component';
import { ServiceLocator } from '../Services/locator.service';


@Injectable({
    providedIn: "root",
})
export abstract class MainComponent implements OnInit, AfterViewInit {
    public router: Router;
    public activatedRoute: ActivatedRoute;
    public broadcast: Broadcast;

    public LookupData: any = {};

    public SharedData: any = {};
    public resolver: any;
    public componentRef: any;
    public Controller: string = "";
    public Route: string = "";
    public Parameters: any[] = [];
    public Module: string = "";

  
    public container: any;

  
    public lookupConfigs: any = {};
    constructor() {
        this.activatedRoute = ServiceLocator.injector.get(ActivatedRoute);
        this.router = ServiceLocator.injector.get(Router);
        this.broadcast = ServiceLocator.injector.get(Broadcast);
        this.resolver = ServiceLocator.injector.get(ComponentFactoryResolver)
        this.broadcast.observable<any>("container").subscribe((x: any) => {
            
            this.container = x.value;
        });
    }

    async ngOnInit() {
        
    }
    async ngAfterViewInit() {
        

    }
    navigate(url: string) {
        this.router.navigate([url]);
    }

    showLookup(
       //debugger
        controller?: any, route?: any, parameters?: any[], module?: any
    ) {
        
        if (this.container) {
            this.container.clear();
            const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
                LookupComponent
            );
            
            this.componentRef = this.container.createComponent(factory);
            
            this.componentRef.instance.open(controller, route, parameters, module);
        }
    }

    protected async showToolbar(value: Boolean) {
        await this.broadcast.publish<Boolean>("isToolbar", value);
    }

    protected async customList(value: any) {
        await this.broadcast.publish<any>("customList", value);
    }

    protected async hideNewToolbar(value: Boolean) {
        await this.broadcast.publish<Boolean>("hideNew", value);
    }

    protected async hideSaveToolbar(value: Boolean) {
        await this.broadcast.publish<Boolean>("hideSave", value);
    }
    protected async hideSearchToolbar(value: Boolean) {
        await this.broadcast.publish<Boolean>("hideSearch", value);
    }

    protected async hideDeleteToolbar(value: Boolean) {
        await this.broadcast.publish<Boolean>("hideDelete", value);
    }
    protected async hidePrintToolbar(value: Boolean) {
        await this.broadcast.publish<Boolean>("hidePrint", value);
    }

}
